import React, {useState} from "react"
import { graphql } from "gatsby"
import SEO from '../components/seo'
import Layout from '../components/layout'
import ContentBlock from '../components/ContentBlock/ContentBlock'
import PageTitle from '../components/PageTitle/PageTitle'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { Link } from 'gatsby'

const buttonStyle = {
  backgroundColor: "#3E597F",
  borderColor: "#3E597F",
  borderRadius: "50px",
  fontFamily: "allotrope, sans-serif",
  fontWeight: 700,
  fontSize: "1rem",
  paddingTop: ".5rem",
  paddingLeft: "2rem",
  paddingRight: "2rem",
}


function AirportModal(props) {
  const [modalShow, setModalShow] = useState(false)

  return (
      <>
  <div>
  <div className="btn btn-primary btn-sm" onClick={() => setModalShow(true)}
      style={buttonStyle}>
      Groton Area Airports
  </div>
  </div>
          <JoinOurTeamModal 
              show={modalShow}
              onHide={() => setModalShow(false)}
          />
      </>
  )
}

const JoinOurTeamModal = (props) => (
  <Modal
  {...props}
  size="lg"
  aria-labelledby="contained-modal-title-vcenter"
  centered
>
  <Modal.Header closeButton>
      <Row>
          <Col>
              <div>
                  <h2>Airports</h2>
              </div>
          </Col>
      </Row>
  </Modal.Header>
  <Modal.Body>
      <p>
      There are two main airports that serve the Groton area:
      </p>
      <p><strong>Rhode Island T.F. Green International Airport (PVD)</strong> 200 Post Road, Warwick, RI 02886 <a href="https://flyri.com/" target="_blank" rel="noopener noreferrer">https://flyri.com/</a> ...is located south of Providence Rhode Island and 50 miles from the Groton area.  It takes approximately 50 minutes to drive from the airport during normal weekend traffic using Interstante 95.  Traffic during the week is normally not that affected by commuter traffic possibly right at rush hour given there are not cities between the airport and Groton.</p>
      <p><strong>Bradley International Airport (BDL)</strong> Schoephoester Rd, Windsor Locks, CT 06096 <a href="https://bradleyairport.com/" target="_blank" rel="noopener noreferrer">https://bradleyairport.com/</a> ...is located north of the Hartford metropolitan area 66 miles from Groton.  It takes approximatley 1hr 10min to drive from the airport during normal weekend traffic using Interstantes 91m, State Hwy 2, Interstate 395 and State Hwy 12.  However, traffic during the week varies DRAMTICALLY given you have to travel through the Hartford metropolitan area to get to Groton.</p>
      <p><strong>Groton-New London Airport (KGON)</strong> <a href="https://ctairports.org/airports/groton-newlondon/"  target="_blank" rel="noopener noreferrer">https://ctairports.org/airports/groton-newlondon/</a> Groton does have a small general aviation airport. No commercial flights are listed as serving this airport</p>
  </Modal.Body>
</Modal>
)

const options = {
        renderNode: {
            
            [BLOCKS.EMBEDDED_ASSET]: ({data: { target: { fields }}}) => 
                <div dangerouslySetInnerHTML={{
                    __html: `<div style="width: auto; float: right; clear: both; margin-left: 20px; ">
                    <img src="${fields.file["en-US"].url}" alt="${fields.title["en-US"]}" class="img-fluid" style="margin: 0px" />
                    <p style="text-align: center; max-width: 320px;">
                      <em>${fields.title["en-US"]}</em>
                    </p>
                    </div>`,
                  }} />,

                [BLOCKS.EMBEDDED_ENTRY]: (node) => {
                    console.log(node.data.target.fields.images)
                    const fields = node.data.target.fields.images["en-US"];
                        return (
                            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                            {fields.map((galleryImage) =>
                                <div style={{width: '50%', padding: '1rem'}} dangerouslySetInnerHTML={{__html: `<div><img src="${galleryImage.fields.file["en-US"].url}" alt="${galleryImage.fields.title}" style="width: 100%" />
                                <small>${galleryImage.fields.description["en-US"]}</small>
                                </div>` }} />
                            )}
                            </div>
                            
                        
                        )
                    },
                
                // Embed iframe content
                [INLINES.EMBEDDED_ENTRY]: (node) => {
                    const embed = node.data.target.fields?.markdownContent;
                   // console.log('embed data: ' + embed['en-US'])
                    return (
                        <div dangerouslySetInnerHTML={{__html: `${embed['en-US']}`}} />
                    )
                },
        }
    }

const Events = ({ data }) => {

  const events = data.contentfulUpcomingEventsPage

  //const photos = data.allContentfulGallery.edges[0].node.images;
  return (

<Layout>
<SEO title="Upcoming Events"/>
<Container fluid className="container-fluid-full">
  <PageTitle pageTitle="Upcoming Events" >
    {/* {page.pageTitle} */}
  </PageTitle>
  <ContentBlock>
        <div style={{
          display: 'flex',
          flexDirection: 'column'
        }}>
        <div style={{
          backgroundColor: "#3E597F",
          borderRadius: "8px",
          padding: "2%",
          marginBottom: "2rem",
        }}>
          <h1 style={{ color: "white", marginBottom: 0 }}>
            USS IDAHO Christening Date in Groton, Connecticut has been set for
            Saturday, March 16 2024
          </h1>
        </div>
        <div>
        <h3>
          We are excited to be able announce that the U.S. Navy's newest Virginia-class submarine, the future USS IDAHO SSN 799, will be formally named and christened on March 16 at General Dynamics Electric Boat Shipyard at 75 Eastern Point Rd, Groton, Connecticut.
          </h3>
          <p>
          We will have an update in the future as to the start time of the ceremony.
          </p>
          <p>
          At the event, in a long-standing naval tradition, Ship Sponsor Terry Stackley will christen the submarine in the traditional manner with a bottle. In a uniquely Idahoan twist, rather than breaking a bottle of champagne on the boat's hull, Sponsor Terry Stackley will christen it with a bottle containing water from four of Idaho's lakes, Lake Pend Oreille, Payette Lake, Henrys Lake, and Redfish Lake. This is the first U.S. Navy warship to bear the name USS IDAHO in more than 100 years; the battleship USS IDAHO BB42 was christened in June 1917.
          </p>
          <p>
          If you would like request a ticket to the Christening from General Dynamics Electric Boat click on this link to take you to the request form:
          </p>
          <div style={{marginBottom: '1rem'}}>
            <Link
              style={buttonStyle}
              to="/christening-request"
              className="btn btn-primary btn-sm"
            >
              Electric Boat Ticket Request
            </Link>
          </div>
          <p style={{fontWeight: 'bold'}}>
          Please note that tickets to the Christening of USS IDAHO SSN 799 are controlled by and at the discretion of General Dynamics Electric Boat Shipyard. The USS IDAHO Commissioning Committee will do everything it can do to advocate on your behalf but we cannot make any guarantees, implied or otherwise, as to whether you will be offered and/or receive a ticket or the number of tickets you requested from Electric Boat.
          </p>
        </div>
        <div style={{marginBottom: '1rem'}}>
            <Link
              style={buttonStyle}
              to="https://assets.ctfassets.net/3y19xtrybxm5/qDvaDScH1FSIQ816DFSPE/9c452efe6974f61bd22d3397b68f5265/HotelListGrotonAreaPosted10SEP2023.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary btn-sm"
            >
              Groton Area Hotel List (PDF)
            </Link>
          </div>
        <div>
          <AirportModal />
        </div>

        <div style={{
          marginTop: '2rem'
        }}>
          <h3>
            USS IDAHO Christening Watch Parties
          </h3>
          <p>
            <strong>Gather with your friends, watch a live stream of the christening, and toast to the USS IDAHO SSN 799 with special USS IDAHO commemorative beer, named by our submarine crew, at the locations listed below:</strong>
            </p>
          <Row>
            <Col>
              <h4>
                Sockeye Brewery
              </h4>
              <p>
              3823 N Garden Center Way<br />
              Boise, ID 83703<br />
              Time: TBD<br />
              March 16, 2024
              </p>
            </Col>
            <Col>
              <h4>
                Idaho Brewing Company
              </h4>
              <p>
              775 S Capital Ave.<br />
              Idaho Falls, ID 83402<br />
              Time: TBD<br />
              March 16, 2024
              </p>
            </Col>
          </Row>
          <p>
          Additional watch parties are being planned. Please check back for updates!
            </p>
        </div>

      {/* {events.event.map((item, index) => {
        return (
          <div key={index} className="mb-4 events" id={item.anchorId}>
            <h2>{item.eventTitle}</h2>
            <div>
              {documentToReactComponents(item.childContentfulUpcomingEventEventDescriptionRichTextNode.json, options)}
            </div>
          </div>
        )
      })} */}
     
    
      {/* {events.keyEvent.map((keyItem, idx) => {
        return (
          <div key={idx} className="mb-4 events">
            <h2>{keyItem.eventTitle}</h2>
            <div>
              {documentToReactComponents(keyItem.childContentfulUpcomingEventEventDescriptionRichTextNode.json, options)}
            </div>
          </div>
        )
      })} */}
      </div>

    {/* <h2 style={{marginBottom: '3rem'}}>Check back soon for upcoming events!</h2> */}
   

   </ContentBlock>
    </Container>
</Layout>
  )
}
export const query = graphql`
{
  contentfulUpcomingEventsPage {
    event {
      anchorId
      eventTitle
      childContentfulUpcomingEventEventDescriptionRichTextNode {
        json
      }
    }
  }
  fullyLoaded: file(relativePath: {eq: "fully-loaded.jpg"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  crew1: file(relativePath: {eq: "crew-1.jpg"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  crew2: file(relativePath: {eq: "crew-2.jpg"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  crew3: file(relativePath: {eq: "crew-3.jpg"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  crew4: file(relativePath: {eq: "crew-4.jpg"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  crew5: file(relativePath: {eq: "crew-5.jpg"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  baseball: file(relativePath: {eq: "baseball.jpg"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`

export default Events
